const menu = document.querySelector('.js-menu');
const menuButtons = document.querySelectorAll('.js-menu-btn');

if (menu) {
    menuButtons.forEach(menuButton => {
        menuButton.addEventListener('click', () => {
            menu.classList.toggle('active');
            menuButton.classList.toggle('active');
        });
    });
}

const faqItems = document.querySelectorAll(".faq__item");
function toggleAccordion() {
    const itemToggle = this.getAttribute('aria-expanded');
    const content = this.querySelector('.faq__content');
    const contentHeight = content.scrollHeight;

    if (itemToggle == 'false') {
        this.setAttribute('aria-expanded', 'true');
        content.style.height = `${contentHeight}px`;
    } else {
        this.setAttribute('aria-expanded', 'false');
        content.style.height = 0;
    }
}

faqItems.forEach(item => item.addEventListener('click', toggleAccordion));


// const links = document.querySelectorAll('a[href="#"]');
// links.forEach(link => {
//     link.href = "/go/";
//     link.setAttribute('target', '_blank');
// });
